// @flow
import { extendObservable, observable, action, runInAction } from "mobx";
import moment from "moment";
import NetworkState from "./NetworkState";

class LibraryInfo<T> {
  jobTitle: T;
  category: string;
  mappedJobTitleId: number;
  mappedJobTitleName: string;
  certifiedCountries: number[];
  adhocCountries: number[];
  searchableCountries: number[];
  created: Object;
  createdDisplay: string;

  constructor<T>(title: T, dataObject: Object) {
    this.jobTitle = title;

    if (!dataObject) return;

    this.category = dataObject.categoryName;
    this.mappedJobTitleId = dataObject.mappedRawJobTitleId;
    this.mappedJobTitleName = dataObject.mappedRawJobTitleTitle;
    this.certifiedCountries = dataObject.certifiedCountries;
    this.adhocCountries = dataObject.adhocCountries;
    this.searchableCountries = dataObject.searchableCountries;
    this.created = moment(dataObject.created);
    this.createdDisplay = this.created.format("MMMM D, YYYY");
  }
}

class SharedInfoUser {
  id: number;
  firstName: string;
  lastName: string;

  constructor(dataObject: Object) {
    if (!dataObject) return;

    this.id = dataObject.userId;
    this.firstName = dataObject.firstName;
    this.lastName = dataObject.lastName;
  }

  fullName = (): string => {
    const parts = [];
    if (this.firstName) parts.push(this.firstName);
    if (this.lastName) parts.push(this.lastName);

    return parts.join(" ");
  };
}

class SharedInfo {
  isMine: boolean;
  jobLabelId: number;
  searchOnly: boolean;
  sharedBy: SharedInfoUser;

  constructor(dataObject: Object) {
    if (!dataObject) return;

    this.isMine = dataObject.isMine;
    this.jobLabelId = dataObject.jobLabelId;
    this.searchOnly = dataObject.searchOnly;
    if (dataObject.sharedBy) {
      this.sharedBy = new SharedInfoUser(dataObject.sharedBy);
    }
  }
}

export class BatchSearchCreateLabelData {
  jobLabel: string;
  description: string;

  constructor(jobLabel: string, jobDescription: string) {
    extendObservable(this, {
      jobLabel: jobLabel,
      description: jobDescription,
    });
  }
}

export default class RawJobTitle {
  store: Object;
  network: NetworkState;

  id: number;
  title: string;
  description: ?string;
  isJobLabel: boolean;
  collectionId: number;
  jobLabelCategory: string;
  showDescription: ?boolean;
  showQuantiles: ?boolean;
  libraryInfo: LibraryInfo;
  sharedInfo: SharedInfo;
  viewState: {
    selected: boolean,
    editing: boolean,
    expanded: boolean,
  };

  labelData: ?BatchSearchCreateLabelData;

  toString: () => string;
  getDescription: () => Promise<any>;
  toggleEdit: () => void;
  toggleSelected: (?boolean) => void;
  toggleExpanded: () => void;

  constructor(store: Object, object: Object) {
    this.store = store;
    this.network = new NetworkState();
    this.id = object.databaseId ? object.databaseId : object.id;
    this.title = object.title;
    this.isJobLabel = object.isJobLabel;
    this.collectionId = object.collection;
    this.jobLabelCategory = object.category;
    this.description = object.description;
    this.showDescription = object.showDescription;
    this.showQuantiles = object.showQuantiles;
    this.labelData = null;

    if (object.clientJobLibraryInfo) {
      this.libraryInfo = new LibraryInfo<RawJobTitle>(this, object.clientJobLibraryInfo);
    }

    if (object.shareInfo) {
      this.sharedInfo = new SharedInfo(object.shareInfo);
    }

    extendObservable(this, {
      description: object.description || null,
      viewState: observable({
        selected: false,
        expanded: false,
        editing: false,
      }),
    });

    this.getDescription = action(this.getDescription.bind(this));
    this.toggleEdit = action(this.toggleEdit.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.toggleExpanded = action(this.toggleExpanded.bind(this));
  }

  toggleEdit() {
    const viewState = this.store.jobTitlesViewState.get(this.id);

    viewState.editing = !viewState.editing;
  }

  toggleSelected(setValue: ?boolean = null) {
    if (!this.store.jobTitlesViewState.has(this.id)) {
      this.store.jobTitlesViewState.set(this.id, {
        selected: false,
        editing: false,
        expanded: false,
      });
    }

    const viewState = this.store.jobTitlesViewState.get(this.id);
    this.viewState = viewState;

    if (setValue !== null) {
      viewState.selected = setValue;
      // return;
    } else {
      viewState.selected = !viewState.selected;

      if (!viewState.selected && this.store.allSelected) {
        this.store.allSelected = false;
      }
    }

    if (viewState.selected) {
      this.store.jobTitlesSelected.set(this.id, this);
    } else {
      this.store.jobTitlesSelected.delete(this.id);
    }

    if (this.store.jobTitlesSelected.size > 0) {
      this.store.addTitleWizard.goTo(4);
    } else {
      this.store.addTitleWizard.goTo(3);
    }

    // Multiple selection is always allowed
    // if (!this.store.allowMultipleItemSelection) {
    // // deselect all other rate cards
    // this.store.jobTitlesViewState.forEach(viewState => {
    //   if (this.viewState === viewState) return;
    //
    //   viewState.selected = false;
    // });
    // }
  }

  toggleExpanded() {
    this.viewState.expanded = !this.viewState.expanded;
    if (this.viewState.expanded) {
      this.getDescription();
    }
  }

  toString(): string {
    return this.title;
  }

  async getDescription(): Promise<any> {
    if (this.network.loading) return;

    if (this.description) return;

    const query = `
    query getRawJobTitleDescription($databaseId: ID!) {
      rawJobTitle(databaseId: $databaseId) {
        description
      }
    }
    `;
    const variables = {
      databaseId: this.id,
    };

    // const requestPayload = {
    //   query: query,
    //   variables: variables
    // };

    this.network.loading = true;

    let payload = null;
    try {
      payload = await this.store.fetchTasteGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting Raw Job Title Description", e);
      if (payload !== null) {
        this.network.logGraphQLError("Get Raw Job Title Description query", payload);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getDescription--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Raw Job Title Description query", payload)) {
        // TODO: Display user friendly error message
        return null;
      }

      if (!payload || !payload.data || !payload.data.rawJobTitle) return;

      this.description = payload.data.rawJobTitle.description;
      return this.description;
    });
  }

  clone = (): Object => {
    const data = {
      id: this.id,
      title: this.title,
      collection: this.collectionId,
      isJobLabel: this.isJobLabel,
      category: this.jobLabelCategory,
      showDescription: this.showDescription,
      showQuantiles: this.showQuantiles,
      shareInfo: this.sharedInfo
        ? {
            jobLabelId: this.sharedInfo.jobLabelId,
            searchOnly: this.sharedInfo.searchOnly,
            isMine: this.sharedInfo.isMine,
            sharedBy: this.sharedInfo.sharedBy
              ? {
                  userId: this.sharedInfo.sharedBy.id,
                  firstName: this.sharedInfo.sharedBy.firstName,
                  lastName: this.sharedInfo.sharedBy.lastName,
                }
              : null,
          }
        : null,

      clientJobLibraryInfo: this.libraryInfo
        ? {
            created: this.libraryInfo.created,
            categoryName: this.libraryInfo.category,
            adhocCountries: this.libraryInfo.adhocCountries,
            certifiedCountries: this.libraryInfo.certifiedCountries,
            searchableCountries: this.libraryInfo.searchableCountries,
            mappedRawJobTitleTitle: this.libraryInfo.mappedJobTitleName,
            mappedRawJobTitleId: this.libraryInfo.mappedJobTitleId,
          }
        : null,
    };
    return new RawJobTitle(this.store, data);
  };
}
