import type { Options as HighchartsOptions, PointOptionsType } from "highcharts";
import { config } from "../../../../stitches.config";

const colors = config.theme.colors;

export function getBellCurveConfig(
  data: number[],
  formatRate: (rate?: number) => string
): HighchartsOptions {
  return {
    chart: {
      events: {
        load() {
          const columns = this.series[0].data.map((point, idx) => {
            const value: Partial<PointOptionsType> = {
              x: point.x,
              y: point.y,
            };

            if (point.x > 0) {
              // const middlePoint = Math.round(this.series[0].data.length / 2) - 1;
              // const isIndexTheMiddlePoint = idx === middlePoint;
              point.update({
                marker: {
                  enabled: true,
                  radius: 6,
                  lineColor: "white",
                  lineWidth: 2,
                  fillColor: colors.primary,
                },
              });
            } else {
              point.update({
                marker: {
                  enabled: false,
                },
              });
            }

            return value;
          });

          this.series[2].setData(columns);
        },
      },
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },
    legend: {
      enabled: false,
    },

    xAxis: [
      {
        visible: false,
      },
      {
        visible: false,
      },
      {
        visible: false,
      },
    ],

    yAxis: [
      {
        visible: false,
      },
      {
        visible: false,
      },
    ],

    tooltip: {
      formatter() {
        const rate = Number(this.x ?? -1);
        if (rate < 0) return false;
        const [value, suffix] = formatRate(rate).split("/");
        return `<span style="font-size: 14px"><b>${value}</b>/${suffix}</span>`;
      },
    },

    series: [
      {
        type: "bellcurve",
        name: "Bill Rate",
        baseSeries: 1,
        fillColor: "transparent",
        lineWidth: 4,
        intervals: 4,
        xAxis: 1,
        yAxis: 1,
        lineColor: config.theme.colors.accent,
        marker: {
          enabled: false,
        },
      },
      {
        data,
        name: "Data",
        type: "scatter",
        visible: false,
      },
      {
        name: "Bill Rate",
        zIndex: -1,
        xAxis: 2,
        type: "column",
        opacity: 0.4,
        color: config.theme.colors.accentDarkest,
        data: [],
      },
    ],
  };
}
